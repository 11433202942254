///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

	#footer {
		.copyright {
			font-size: 0.8em;
			list-style: none;
			padding-left: 0;

			li {
				border-left: solid 1px _palette(border);
				color: _palette(fg-light);
				display: inline-block;
				line-height: 1;
				margin-left: 1em;
				padding-left: 1em;

				&:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}
		}

		@include breakpoint(xsmall) {
			.copyright {
				li {
					display: block;
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
					line-height: inherit;
				}
			}
		}
	}



.gam {
    font-weight: bold;
    color: #2a2a2a;
	white-space: nowrap;
    background-color: #fff;
    padding-left: 0.2em;
    border-radius: 0.25em 0 0 0.25em;
}

.inside {
    font-weight: bold;
    color: #008CB8;
	white-space: nowrap;
    background-color: #fff;
    padding-right: 0.2em;
    border-radius: 0 0.25em 0.25em 0;
}

#home {
    #main {
        img.fg {
            background-color: #ffffff;
        }
        .space {
            height: 5em;
        }
        #two {
            background-color: rgba(_palette(accent1), 0.7);
        }
        #three {
            background-color: rgba(_palette(accent2), 0.7);
        }
        #four {
            background-color: rgba(_palette(accent3), 0.7);
        }
        #five {
            background-color: rgba(_palette(accent4), 0.7);
        }
        #six {
            background-color: rgba(_palette(accent5), 0.7);
        }
        #seven {
            background-color: rgba(_palette(accent6), 0.7);
        }
    }
}

